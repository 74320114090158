table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid white;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #333;
  color: white;
}

td {
  background-color: #444;
}
