*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.calendar-container {
  background-color: var(--clr-black);
  color: var(--clr-white);
  padding: 2rem;
}

.rbc-header {
  height: 50px;
}

.rbc-time-view {
  background-color: ivory;
}

.rbc-calendar {
  background-color: var(--clr-white);
  color: var(--clr-black);
}

.rbc-event {
  background-color: #3174ad;
  color: white;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.rbc-timeslot-group {
  min-height: 100px;
}

.rbc-toolbar {
  background-color: #1a1a1a;
}

.rbc-toolbar-label {
  color: var(--clr-big-yellow);
  font-weight: bold;
}

.rbc-toolbar button {
  background-color: var(--clr-black);
  border: 2px solid transparent;
  border-image: linear-gradient(45deg, #d08932, #ffe15a) 1;
  font-weight: bold;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  margin: 0.2rem;
  transition: var(--transition);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(45deg, #d08932, #ffe15a);
  -webkit-text-fill-color: transparent;
}

.rbc-toolbar button:hover {
  background-color: var(--clr-big-yellow);
}

.modal-custom {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 0;
  border-radius: 10px;
  position: relative;
  background-color: transparent;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  background-color: #1a1a1a;
  margin-top: 50px;
}

.modal-wrapper::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(
    45deg,
    var(--clr-big-yellow),
    var(--clr-light-yellow)
  );
  border-radius: 12px;
  z-index: -5;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

.modal-title,
.modal-subtitle {
  color: var(--clr-big-yellow);
}

.rbc-row-content,
.rbc-allday-cell {
  display: none;
}

@media (min-width: 100%) {
  .calendar-container {
    padding: 1rem;
  }

  .rbc-toolbar {
    flex-direction: column;
    align-items: center;
  }

  .rbc-toolbar button {
    padding: 0.5rem 1rem;
    margin: 0.2rem 0;
  }

  .rbc-time-view,
  .rbc-calendar {
    width: 100%;
    overflow-x: auto;
  }

  .rbc-time-view .rbc-time-header {
    flex-direction: column;
  }

  .rbc-time-view .rbc-time-header-cell {
    text-align: center;
  }

  .modal-custom {
    width: 90%;
    max-width: 400px;
  }

  .rbc-label.rbc-time-header-gutter {
    /* display: block; */
    /* width: 67px; */
    /* min-width: 67px !important; */
    /* max-width: 67px; */
  }
}

/* @media (max-width: 780px) {
  .rbc-label.rbc-time-header-gutter {
    display: block;
    width: 90px;
  }
} */

/* .rbc-label.rbc-time-header-gutter {
  display: block !important;
  width: 90px !important;
} */

@media (max-width: 480px) {
  .text-title {
    font-size: 30px;
  }
  .rbc-label {
    font-size: 0.5em;
    font-weight: bold;
  }

  .rbc-row-content,
  .rbc-allday-cell {
    display: none;
  }

  .rbc-header button {
    font-size: 0.5em;
    font-weight: bold;
  }

  .rbc-label.rbc-time-header-gutter {
    display: block;
    width: 50px;
  }

  .rbc-event {
    font-size: 0.8em;
    padding: 0.3rem;
  }

  .rbc-timeslot-group {
    min-height: 60px;
  }

  .modal-custom {
    width: 95%;
    max-width: 350px;
  }

  .modal-title {
    font-size: 2rem;
  }

  .modal-subtitle {
    font-size: 1.2rem;
  }
}

.class-filter-dropdown {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

@media (max-width: 768px) {
  .class-filter-dropdown {
    font-size: 16px;
    padding: 15px;
    width: 50%;
    max-width: 400px;
  }

  .class-filter-dropdown option {
    font-size: 20px;
  }
}
