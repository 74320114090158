.title {
  color: var(--clr-big-yellow);
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 20px;
    color: var(--clr-big-yellow);
  }
  .text {
    font-size: 14px;
  }
  .section-content {
    font-size: 16px;
  }
}
