.header {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url("../../assets/images/header_main_img.jpg") center/cover no-repeat;
}
.header-content .text-lead {
  margin: 3.2rem 0 4.9rem 0;
}

.header-logo {
  max-width: 300px;
  margin-left: 10px;
}

.header-title {
  font-size: 50px;
  font-weight: 900;
  line-height: 1.2;
  background: linear-gradient(
    to right,
    var(--clr-light-yellow),
    var(--clr-big-yellow)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-btn {
  align-self: center !important;
}
.header-btn span {
  margin-left: 10px;
}

@media screen and (min-width: 600px) {
  .header-title {
    font-size: 88px;
  }
  .header-content * {
    max-width: 920px;
  }
}
