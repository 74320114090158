.contact-info-group {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically */
  margin-bottom: 20px;
}

.info-details {
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  margin-left: 10px; /* Add spacing between icon and details */
  align-items: flex-start;
}

.item-icon {
  margin-right: 10px; /* Add some spacing between icon and text */
}

.text-white {
  color: white; /* Set text color to white */
}

.info .item-info-text {
  font-size: 20px;
  margin: 0;
  text-align: justify;
}
