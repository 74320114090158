.admin-classes {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.admin-classes h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.admin-classes form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.admin-classes form div {
  display: flex;
  flex-direction: column;
}

.admin-classes form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.admin-classes form input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admin-classes form button {
  padding: 0.75rem;
  background-color: #3174ad;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-classes form button:hover {
  background-color: #255d8a;
}

.admin-classes ul {
  list-style: none;
  padding: 0;
}

.admin-classes ul li {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ccc;
}

.admin-classes ul li div {
  flex: 1;
}

.admin-classes ul li button {
  padding: 0.5rem;
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-classes ul li button:hover {
  background-color: #c53030;
}
