.video-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* Adjust the height as needed */
}

.video-player {
  max-width: 800px; /* Adjust the maximum width of the player */
  width: 100%;
}
