@media screen and (max-width: 600px) {
  .text-title {
    font-size: 20px;
    margin-top: 20px;
  }

  .toggle-button {
    position: relative;
  }

  .section-plan {
    padding-bottom: 50px;
  }
}

.toggle-switch-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligns the switch to the right */
  margin-top: 20px; /* Adjust as needed */
}

.toggle-switch {
  display: flex;
  align-items: center;
}

.toggle-switch span {
  margin-right: 10px; /* Adjust as needed */
}

.toggle-switch input {
  display: none;
}

.toggle-switch label {
  display: block;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.toggle-switch label:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
}

.toggle-switch input:checked + label {
  background: linear-gradient(var(--clr-light-yellow), var(--clr-big-yellow));
}

.toggle-switch input:checked + label:before {
  transform: translateX(30px);
}
