.card {
  padding: 48px 0px;
  position: relative;
  transition: var(--transition);
  width: 100%; /* Ensure the card takes up the entire width */
  height: auto; /* Adjust height as needed */
}

.name {
  width: 60px;
}

.price {
  width: 80px;
}

.card-action-item-img {
  width: 20%;
  position: relative;
}

.card-front,
.card-back {
  width: 100%;
  height: 120%; /* Adjust height as needed */
  position: absolute;
  top: 0; /* Position the elements at the top of the card */
  left: 0; /* Position the elements at the left of the card */
  backface-visibility: hidden;
}

.card-front {
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: transform 0.5s;
}

.card-back {
  transform: rotateX(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s;
  font-size: 16px;
}

.card.is-flipped .card-front {
  transform: rotateX(180deg);
}

.card.is-flipped .card-back {
  transform: rotateX(0deg);
}

.card p {
  margin: 0;
  font-size: 20px;
}
